@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}


  .chakra-date-picker .react-datepicker {
    font-family: inherit;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .chakra-date-picker .react-datepicker-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: brand.500;
    color: white;
    padding: 8px 0;
  }
  .chakra-date-picker .react-datepicker__day-name,
  .chakra-date-picker .react-datepicker__day {
    width: 2.5rem;
    line-height: 2.5rem;
  }
  .chakra-date-picker .react-datepicker__day--selected,
  .chakra-date-picker .react-datepicker__day--in-selecting-range,
  .chakra-date-picker .react-datepicker__day--in-range,
  .chakra-date-picker .react-datepicker__month-text--selected,
  .chakra-date-picker .react-datepicker__month-text--in-selecting-range,
  .chakra-date-picker .react-datepicker__month-text--in-range,
  .chakra-date-picker .react-datepicker__quarter-text--selected,
  .chakra-date-picker .react-datepicker__quarter-text--in-selecting-range,
  .chakra-date-picker .react-datepicker__quarter-text--in-range {
    background-color: brand.500;
    color: white;
  }

  .custom-datetime-picker .rdtPicker {
    background-color: #f0f0f0; /* Set your desired background color */
  }
